#arrowAnim {
  width: 50vw;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.arrow {
  width: 5vw;
  height: 5vw;
  border: 2.5vw solid;
  border-color: #73be44 transparent transparent #73be44;
  transform: rotate(-45deg);
}

.arrowSliding {
  position: absolute;
  -webkit-animation: slide 4s linear infinite;
  animation: slide 4s linear infinite;
}

.delay1 {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}
.delay2 {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}
.delay3 {
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}

@-webkit-keyframes slide {
  0% {
    opacity: 0;
    transform: translateX(15vw);
  }
  20% {
    opacity: 1;
    transform: translateX(9vw);
  }
  80% {
    opacity: 1;
    transform: translateX(-9vw);
  }
  100% {
    opacity: 0;
    transform: translateX(-15vw);
  }
}
@keyframes slide {
  0% {
    opacity: 0;
    transform: translateX(15vw);
  }
  20% {
    opacity: 1;
    transform: translateX(9vw);
  }
  80% {
    opacity: 1;
    transform: translateX(-9vw);
  }
  100% {
    opacity: 0;
    transform: translateX(-15vw);
  }
}

.wrap-info {
  display: flex;
  justify-content: space-between;
}

div.wrap-info .icon-info {
  color: red;
  cursor: pointer;
}
